import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { portugueseMessages } from './languages/pt';
import { englishMessages } from './languages/en';
i18n.use(initReactI18next)
.init({
    lng: navigator.language.slice(0,2),
    fallbackLng:'pt',
    interpolation: {
        escapeValue: false,
    },
    resources: {...englishMessages,...portugueseMessages},
});

export default i18n;