import Capa from "./components/Capa/Capa";
import NomeCapa from "./components/NomeCapa/NomeCapa";
import Chamada from "./components/Chamada/Chamada";
import Habilidades from "./components/Habilidades/Habilidades";
import Projetos from "./components/Projetos/Projetos";
import Contato from "./components/Contato/Contato";
import Footer from "./components/Footer/Footer";
import './translate/i18n';

function App() {

  return (
    <div className="text-white font-primary box-border tracking-wider">
      <Capa/>
      <NomeCapa />
      <Chamada />
      <Habilidades />
      <Projetos />
      <Contato />
      <Footer />
    </div>
  );
}


export default App;
