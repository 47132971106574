type Props = {
    icone : string,
    titulo : string,
    lista : String[]
}


function HabilidadeContainer(props :  Props){
    return(
        <div className="text-center">
            <span className="material-symbols-outlined text-iconeGrande select-none">
            {props.icone}
            </span>
            <h4 className="font-bold text-2xl pb-3">{props.titulo}</h4>
            <ul>
                {props.lista.map((item, index) => (
                        <li className="font-light text-md" key={index}>{item}</li>
                    ))}
            </ul>
        </div>
    )

}

export default HabilidadeContainer;