type Props = {
    icone : string,
    texto : string
}

function DiferenciaisContainer(props : Props){


    return(
        <div className="text-center max-w-[25%] min-w-[200px] sm:w-full">
            <span className="material-symbols-outlined text-iconeGrande select-none">
            {props.icone}
            </span>
            <p className="px-4 font-light text-xl">
            {props.texto}
            </p>
        </div>
    )
}

export default DiferenciaisContainer;