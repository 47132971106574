import { useTranslation } from "react-i18next";


function Chamada() {

    const { t } = useTranslation();

    return (
        <div className="bg-chamativa z-20 relative text-center flex justify-evenly">
            <h4 className="lg:text-4xl md:text-3xl font-black py-10 w-1/2">{t('chamada')}</h4>
        </div>
    )
}

export default Chamada;
