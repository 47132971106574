import Container from "../Container/Container";
import ContatoIcon from "../ContatoIcon/ContatoIcon";
import imgWpp from '../../imagens/whatsapp.svg';
import imgGit from '../../imagens/github-mark.svg';
import imgLinkedin from '../../imagens/linkedin.svg';
import { useTranslation } from "react-i18next";

function Contato() {

    const { t } = useTranslation();

    return (

        <div className="bg-bg_primary pt-20 pb-40 relative" id="contato">
            <Container>
                <h2 className="font-black text-6xl text-center pb-20">
                    {t('contato.titulo')}
                </h2>
                <div className="w-full max-w-[900px] mx-auto h-xl mt-10 flex flex-row">
                    <ContatoIcon img={imgWpp} legenda="Whatsapp" link="https://api.whatsapp.com/send/?phone=55065999124321&text&type=phone_number&app_absent=0" />
                    <ContatoIcon img={imgLinkedin} legenda="LinkedIn" link="https://linkedin.com/in/juancassemiro/" />
                    <ContatoIcon img={imgGit} legenda="GitHub" link="https://github.com/JCssemiro" />
                </div>
            </Container>
            <span className="lg:text-fundo1 sm:text-fundo2 font-black absolute top-1/3 right-4 select-none">{t('fundo.contato')}</span>
        </div>
    )
}

export default Contato;
/*
                <form onSubmit={handleForm} action="#" className="text-center text-black flex flex-col justify-center items-center">
                    <input name="nome" value={dados.nome} onChange={handleInput} required type="text" placeholder={t('contato.placeholder.nome')} className="valid:border-green-500 border-2 border-transparent w-full transition-all max-w-[600px] h-[52px] outline-none rounded-md pl-4 text-xl"/>
                    <input name="email" value={dados.email} onChange={handleInput} required type="email" pattern=".+@+.*" placeholder={t('contato.placeholder.email')} className="valid:border-green-500 border-2 border-transparent w-full max-w-[600px] h-[52px] outline-none rounded-md pl-4 text-xl mt-6"/>
                    <textarea name="mensagem" value={dados.mensagem} onChange={handleInput} required placeholder={t('contato.placeholder.mensagem')} className="valid:border-green-500 w-full border-2 border-transparent max-w-[600px] h-[250px] outline-none rounded-md p-4 text-xl mt-6 resize-none "></textarea>
                    <button type="submit" className="px-20 py-4 bg-chamativa text-white rounded-md mt-8 text-2xl hover:bg-white hover:text-chamativa transition duration-300">{loading ? <span className="block w-[40px] h-[40px] border-white border-8 rounded-full border-t-chamativa border-r-chamativa animate-spin"></span>:t('contato.botao')}</button>
                </form>
*/