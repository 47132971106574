import Container from "../Container/Container";
import Navegacao from "../Navegacao/Navegacao";

function Capa (props: any){
    return (
        <section className="pt-24 w-full h-screen bg-gradient-to-b relative from-bg_secondary to-bg_primary overflow-hidden">
            
            <span className="bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-bola1 from-0% to-transparent to-50%
             rounded-full absolute top-[calc(50%-600px)] left-[calc(70%)] h-tamBola w-tamBola opacity-50
             animate-[moverEmCirculo_55s_reverse_infinite] origin-[calc(50%-500px)]"/>

             <span className="bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-bola2 from-0% to-transparent to-50%
             rounded-full absolute top-[calc(50%-250px)] left-[calc(10%-200px)] h-tamBola w-tamBola opacity-50
             animate-[moverVertical_70s_reverse_infinite] origin-[10%]"/>

             <span className="bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-bola3 from-0% to-transparent to-50%
             rounded-full absolute top-[calc(40%-100px)] left-[calc(30%)] h-tamBola w-tamBola opacity-50
             animate-[moverHorizontal_50s_reverse_infinite] origin-[calc(20%-200px)]"/>

             <span className="bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-bola4 from-0% to-transparent to-50%
             rounded-full absolute top-[calc(15%-200px)] left-[calc(50%-200px)] h-tamBola w-tamBola opacity-50
             animate-[moverEmCirculo_58s_ease_infinite] origin-[calc(50%-600px)]"/>
            <Container>
                <Navegacao/>
                
            </Container>
        </section>
    )
}

export default Capa;