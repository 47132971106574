import ProjetoContainer from "../ProjetoContainer/ProjetoContainer"
import Container from "../Container/Container"
import { useTranslation } from "react-i18next"


function Projetos() {
    const { t } = useTranslation();
    const projetos = [{ id: 1, imagem: 'https://images2.imgbox.com/d8/a0/DbdZyHl8_o.png', linkProjeto: 'https://jcssemiro.github.io/GameClicker/', linkGit: 'https://github.com/JCssemiro/GameClicker' },
    { id: 2, imagem: 'https://images2.imgbox.com/78/e8/ZvWqeFxO_o.png', linkProjeto: 'https://infocorpjr.com.br', linkGit: "#" },
    { id: 3, imagem: 'https://i0.wp.com/catagua.com.br/wp-content/uploads/2021/03/confira-as-vantagens-de-escolher-uma-casa-em-condominio-fechado.jpg?fit=1024%2C576&ssl=1', linkProjeto: 'https://github.com/JCssemiro/gerenciadorCondominioBackEnd', linkGit: 'https://github.com/JCssemiro/gerenciadorCondominioBackEnd' }
    ];
    const alturas = [640, 950, 950, 640, 492.5, 492.5, 605];

    return (

        <div className="bg-bg_primary pt-20 relative" id="projetos">

            <Container>
                <div className={projetos.length <= 3 ? "flex flex-wrap lg1:h-auto align-top flex-xrow" : "flex flex-wrap lg1:h-[1590px] align-top flex-col"}>
                    {projetos.map((projeto) => (
                        <ProjetoContainer
                            titulo={t(`projetos.${projeto.id}.titulo`)}
                            cargo={t(`projetos.${projeto.id}.cargo`)}
                            tecnologias={t(`projetos.${projeto.id}.tecnologias`, { returnObjects: true })}
                            imagem={projeto.imagem}
                            link_projeto={projeto.linkProjeto}
                            link_git={projeto.linkGit}
                            descricao={t(`projetos.${projeto.id}.descricao`)}
                            altura={projetos.length <= 3 ? 640 : alturas[projeto.id - 1]}
                            qtdProj={projetos.length} />
                    ))}

                </div>
            </Container>
        </div>

    )
}

export default Projetos