import imgGit from '../../imagens/github-mark.svg';
import { useState,useEffect } from 'react';



type Props = {
    altura : number,
    titulo : String,
    cargo: String,
    tecnologias: String[],
    imagem: String,
    link_projeto : String,
    link_git: String,
    descricao : String,
    qtdProj: number
}



function ProjetoContainer(props : Props){
    const [XContainer,setXContainer] = useState<Number>(100);
    const [YContainer,setYContainer] = useState<Number>(props.altura);

    const handleResize = ()=>{
        if(window.innerWidth >= 1100){
            setYContainer(props.altura);
            if(props.qtdProj <= 3){
                setXContainer(100/props.qtdProj);
            }else if(props.qtdProj === 4){
                setXContainer(50);
            }else{
                setXContainer(100/3);
            }
        }else{
            setXContainer(100)
            setYContainer(640);
        }
    }

    useEffect(()=>{
        handleResize();
    },[])

    window.addEventListener('resize',handleResize);
    

    return(
        <div style={{height: YContainer+'px',width:XContainer+"%"}} className='transition-all sm:w-full p-3 sm:h-[1100px]'>
            <div style={{backgroundImage: `url(${props.imagem})`}} className='bg-cover projetoContainer w-full h-full rounded-md relative'>
                <a href={String(props.link_projeto)} rel="noreferrer" target='_blank'>
                    <div style={{backgroundImage: `url(${props.imagem})`}} className="bg-cover top-0 w-full h-full absolute rounded-md hover:opacity-0 transition-all duration-300 z-20">
                        <div className="w-full h-full bg-black rounded-md opacity-70">
                        </div>
                            <div className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-center w-full select-none">
                                <h2 className="text-4xl">{props.titulo}</h2>
                                <p className="text-xl font-light">{props.cargo}</p>
                                <div className="flex justify-evenly px-4 flex-wrap mt-2">
                                    {props.tecnologias.map((item : String)=>(
                                        <span className=" border border-solid px-3 py-1 m-1 rounded-xl">{item}</span>
                                    ))}
                                </div> 
                            </div>

                    </div>
                    <div>
                        <p className="absolute bottom-0 bg-[rgba(0,0,0,0.7)] p-8 m-3 text-md font-light rounded-md text-justify">
                        {props.descricao}
                        </p>
                    </div>
                </a>
                {props.link_git === "#" ? "" : <a className="w-[64px] h-[64px] absolute right-0 rounded-tr-md rounded-bl-md bg-[rgba(0,0,0,0)] z-30 hover:bg-[rgba(0,0,0,0.7)] transition-all" rel="noreferrer" target='_blank' href={String(props.link_git)}>
                    <img className="w-[48px] h-[48px] mt-2 m-auto relative" src={imgGit} alt='Acessar repositório'/>
                </a>}
                

            </div>
            
        </div>
    )
}

export default ProjetoContainer;