type Props = {
    img : string,
    legenda : string
    link : string
}

function ContatoIcon(porps : Props){


    return(
        <div className="flex w-1/3 justify-center">
                        <a target="_blank" rel="noreferrer" href={porps.link} className="w-iconeGrande h-iconeGrande bg-transparent hover:bg-chamativa transition px-8 pt-5 rounded-md duration-300 text-center">
                        <img alt={porps.legenda} className="w-full" src={porps.img}/>
                        <p className="text-center pt-2 font-light">{porps.legenda}</p>
                        </a>
        </div>
    )
}

export default ContatoIcon;