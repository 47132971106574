import { useTranslation } from "react-i18next";


function NomeCapa() {

    const { t } = useTranslation();

    return (
        <div className="lg:w-full sm:w-1/2 absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 select-none text-center">
            <h3 className="transition-all xl:text-[150px] lg:text-6xl md:text-5xl sm:text-4xl mt-10 leading-none font-black text-center">JUAN CASSEMIRO</h3>
            <a href={`${process.env.PUBLIC_URL}/curriculum.pdf`} rel="noreferrer" target="_blank" className="text-2xl underline underline-offset-4 font-extralight mt-6" >Download CV</a>
        </div>
    )
}

export default NomeCapa;