import { useEffect,useState } from "react";
import { LANGUAGES } from "../../translate/languages";
import { useTranslation } from "react-i18next";





function Navegacao(props: any){
    const [menuAberto,setMenuAberto] = useState(false);
    const [linguagem, setLinguagem] = useState(navigator.language.slice(0,2));
    const {i18n,t} = useTranslation();

    const handleOptionClick = (option : string) => {
        if (linguagem !== option) {
          setLinguagem(option);
          i18n.changeLanguage(option);
        }
    }


    const handleMenu = (menuContainer : any)=>{
        if(menuContainer){

            if(menuContainer.style.height === '0px'){
                if(menuAberto){
                menuContainer.style.height = '160px';
                menuContainer.style.width = '100%';
                menuContainer.style.fontSize = '18px';
                menuContainer.style.opacity = '1';
                }
            }else{
                menuContainer.style.height = '0px';
                menuContainer.style.width = '0px';
                menuContainer.style.fontSize = '0px';
                menuContainer.style.opacity = '0';
    
            }
          };
    }

    useEffect(() => {
        const menu = document.getElementById("menu-container")!;
        handleMenu(menu);
    }, [menuAberto]);
    


    

    
    return(
        <div className="flex justify-between select-none">
            <span className=" duration-300 cursor-pointer material-symbols-outlined text-iconePequeno hover:text-gray-400 transition" id="botao-menu" onClick={()=>{setMenuAberto(!menuAberto)}}>menu</span>
            
            <div id="menu-container" style={{height: '0px'}} className="w-[0px] opacity-0 transition-all text-[0px] w-full md:max-w-[350px] sm:max-w-full absolute sm:left-0 md:left-inherit top-10 backdrop-blur-lg bg-[rgba(0,0,0,0.6)] rounded-md font-light">
                <ul className="h-full">
                    <li className=" h-1/3 align-center hover:bg-[rgba(255,255,255,0.3)] transition duration-300 rounded-t-md"><a className="pl-3 h-full rounded-t-md flex flex-col justify-center" href="#habilidades">{t('menu.habilidades')}</a></li>
                    <li className="h-1/3 hover:bg-[rgba(255,255,255,0.3)] transition duration-300 border-y border-[rgba(255,255,255,0.8)]"><a className="pl-3 h-full flex flex-col justify-center" href="#projetos">{t('menu.projetos')}</a></li>
                    <li className="h-1/3 hover:bg-[rgba(255,255,255,0.3)] transition duration-300 rounded-b-md"><a className="pl-3 h-full rounded-t-md flex flex-col justify-center" href="#contato">{t('menu.contato')}</a></li>

                </ul>
            </div>
                <div className="text-3xl">
                            <div className="inline px-3 cursor-pointer transition-all duration-300"
                                style={{cursor: 'pointer', color: linguagem === LANGUAGES[0].code ? '#9CA38F' : 'white' }}
                                onClick={() => handleOptionClick(LANGUAGES[0].code)}>
                                {LANGUAGES[0].code.toUpperCase()}
                            </div>
                            |
                            <div className="inline px-3 cursor-pointer transition-all duration-300"
                                style={{ cursor: 'pointer', color: linguagem === LANGUAGES[1].code ? '#9CA38F' : 'white' }}
                                onClick={() => handleOptionClick(LANGUAGES[1].code)}>
                                {LANGUAGES[1].code.toUpperCase()}
                            </div>
                </div>
        </div>
    )
}

export default Navegacao;